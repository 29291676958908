import axios from 'axios'
import { API_ENDPOINT } from '../../../constants/apiEndpoint'

/**
 * Get Leads from API
 * @returns {response} response axios
 */
export const fetchAllLeads = () => {
  const url = `${API_ENDPOINT}/leads`
  return axios.get(url).then((r) => r.data)
}
