export function handleError(error, customMessage) {
  console.group(error.message)
  console.log({ error })
  console.error(error)
  console.groupEnd()
  if (error.isAxiosError) {
    const { status, data } = error.response
    const { message } = data.error || data
    return status === 500
      ? customMessage || 'No se pudo realizar la operación'
      : customMessage || message
  }
  return 'Boom! error inesperado, dejamos un regalo para ti en la consola :) \n Happy Hacking'
}
