import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchModules, fetchCondolence } from "./thunk";

// Adaptador para los módulos
const modulesAdapter = createEntityAdapter({
    selectId: (model) => model.moduleId,
    sortComparer: false,
});

const slice = createSlice({
    name: 'OBT',
    initialState: {
        selectedTab: "ET ADS", // Estado inicial de la pestaña
        modules: modulesAdapter.getInitialState(), // Estado inicial de los módulos
    },
    reducers: {
        // Acción para cambiar la pestaña seleccionada
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchModules.fulfilled, modulesAdapter.setAll);
        builder.addCase(fetchCondolence.fulfilled, modulesAdapter.updateOne);
    },
});

// Selectores para obtener los módulos
export const { selectAll: getAllModules } = modulesAdapter.getSelectors(
    (state) => state.OBT.modules
);


export const { setSelectedTab } = slice.actions;

export default slice.reducer;
