import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

export const ButtonMain = styled(Button)`
  height: 40px;
  border: none;
  cursor: pointer;
  padding: 0px 13px;
  border-radius: 4px;
  letter-spacing: 0.3px;
  font: 16px/26px 'DMSans-Medium';
  color: ${(props) =>
    props.primary ? '#FFF' : props.color ? props.color : null};
  background: ${(props) =>
    props.primary ? props.theme.palette.primary.main : null};
  box-shadow: 2px 2px 0px
    ${(props) => (props.primary ? props.theme.palette.primary.dark : 'none')};
  &:hover {
    background: ${(props) =>
      props.primary && props.theme.palette.primary.hover} !important;
  }
  &:focus {
    outline: none;
  }
  .MuiButton-endIcon {
    margin-right: 0px;
  }
`
const useStyles = makeStyles((theme) => ({
  ButtonMain: ({ width }) => ({
    background: theme.palette.primary.main,
    boxShadow: `2px 2px 0px ${theme.palette.primary.dark}`,
    color: theme.palette.blue_darkness,
    height: '40px',
    width: width ? width : 'auto',
    textTransform: 'inherit',
    font: "500 16px/26px 'Heebo', sans-serif",
    float: 'right',
    padding: '0px 13px',
    '&:hover': {
      background: theme.palette.primary.hover,
    },
  }),
}))
export default useStyles
