/* import external modules */
import React, { Suspense, useEffect, useState, useCallback } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components'

/* import internal modules */
import Router from '../../components/Router'
import { store } from '../../redux/storeConfig/store'
import Progress from '../../components/Common/Progress'
import { theme } from '../../constants/themeMaterial'
import { GlobalStyle } from '../../styles/GlobalStyles'
import '../../services/apis/interceptor'
import { getAuthInfo } from '../../services/apis/Login'
import { setUser } from '../../redux/actions/user/user'
import { TOKEN_DB_KEY, USER_AUTH_KEY } from '../../constants/localStorage'
import { LOGIN_URL } from '../../constants/login'
import Modal from '../Common/Modal'
import messages from '../../constants/messages'

/** @description This functional component is a core of the project
 *  @version 1.0.0
 *  @since 23/04/2020
 *  @author Jaime Andrés Gómez Gutiérrez <jaigom.ext@eltiempo.com>
 *
 *  @function
 *  @name App
 *  @returns {Component} Returns redux creation with your children components
 **/

const App = () => {
  const [error, setError] = useState(false)
  const fetchAuthInfo = useCallback((token) => {
    getAuthInfo(token)
      .then((response) => {
        if (response.data.result) {
          // Redirect to login
          window.sessionStorage.clear()
          redirectToLogin()
        } else if (response.data.permissions.length <= 0) {
          window.sessionStorage.clear()
          setError(true)
        } else {
          window.sessionStorage.setItem(
            USER_AUTH_KEY,
            JSON.stringify(response.data)
          )
          store.dispatch(setUser(response.data))
        }
      })
      .catch((err) => {
        window.sessionStorage.clear()
        setError(true)
      })
  }, [])

  useEffect(() => {
    const token = getSessionInfo()
    if (!token) {
      const location = window.location
      if (!location.search) {
        redirectToLogin()
        return
      }
      let url = new URL(window.location.href)
      const code = url.searchParams.get('code')
      if (code) {
        window.sessionStorage.setItem(TOKEN_DB_KEY, code)
        fetchAuthInfo(code)
      } else {
        redirectToLogin()
      }
    } else {
      fetchAuthInfo(token)
    }
  }, [fetchAuthInfo])

  const getSessionInfo = () => {
    const sessionInfo = window.sessionStorage.getItem(TOKEN_DB_KEY)
    if (!sessionInfo) {
      return null
    }
    return sessionInfo
  }

  const redirectToLogin = () => {
    window.location.replace(LOGIN_URL)
  }

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Suspense fallback={<Progress />}>
          <Router />
          {error && (
            <Modal
              open={error}
              onClose={redirectToLogin}
              title={`No se pudo iniciar sesión`}
              onAction={redirectToLogin}
              titleAction={`Aceptar`}
              hideCancelButton
            >
              {messages.messageUnauthorized}
              <br />
            </Modal>
          )}
        </Suspense>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

export default App
