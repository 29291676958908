// import internal modules
import { FETCH_SET_GLOBAL_MESSAGE } from '../../actions/types/types'

const initialState = {
  messages: {},
}

/**
 * Action to requests
 * @param {*} state
 * @param {*} action
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SET_GLOBAL_MESSAGE:
      return {
        ...state,
        messages: action.payload.message,
      }

    default:
      return state
  }
}
