import {
  ADD_MATCH,
  SET_FETCHING_AUDIENCE,
  SET_MATCH_SCOPE,
  SET_MATCH_STATUS,
  SET_SEGEMENTATION_SCOPES,
  SET_SEGMENTATION_TYPES,
  SET_SEGMENTS,
} from '../../actions/types/types'

const initialState = {
  scopes: [],
  types: [],
  segments: [],
  fetching: false,
}

const audienceReducer = (state = initialState, action) => {
  let tmpList = []
  let data = null
  switch (action.type) {
    case SET_SEGEMENTATION_SCOPES:
      return Object.assign({}, state, { scopes: action.payload.scopes })
    case SET_SEGMENTATION_TYPES:
      return Object.assign({}, state, { types: action.payload.types })
    case SET_SEGMENTS:
      return Object.assign({}, state, { segments: action.payload.segments })
    case SET_FETCHING_AUDIENCE:
      return Object.assign({}, state, { fetching: action.payload.fetching })
    case SET_MATCH_SCOPE:
      data = action.payload.data
      tmpList = state.scopes.map((s) => {
        if (s.segmentationMatchId !== data.id) {
          return s
        }
        if (s.segmentationTypeId === 1) {
          s.segments[0].name = data.name
        }
        return { ...s, views: data.views, price: data.price }
      })
      return Object.assign({}, state, { scopes: tmpList })
    case SET_MATCH_STATUS:
      data = action.payload.data
      if (data.status === 0) {
        tmpList = state.scopes.filter((s) => s.segmentationMatchId !== data.id)
      } else {
        tmpList = state.scopes.map((s) =>
          s.segmentationMatchId !== data.id ? s : { ...s, status: data.status }
        )
      }
      return Object.assign({}, state, { scopes: tmpList })
    case ADD_MATCH:
      return Object.assign({}, state, {
        scopes: [...state.scopes, action.payload.match],
      })
    default:
      return state
  }
}

export default audienceReducer
