import { createGlobalStyle } from 'styled-components'
export const GlobalStyle = createGlobalStyle`
    * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    body {  
        font-family: 'DMSans-Regular';
        margin: 0;
        background: #E9E9E9;
    }
    

    
    @font-face {
        font-family: 'icomoon';
        src:  url('/fonts/icomoon.eot?b8hhh6');
        src:  url('/fonts/icomoon.eot?b8hhh6#iefix') format('embedded-opentype'),
            url('/fonts/icomoon.ttf?b8hhh6') format('truetype'),
            url('/fonts/icomoon.woff?b8hhh6') format('woff'),
            url('/fonts/icomoon.svg?b8hhh6#icomoon') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'ads-eltiempo';
        src: url('/fonts/font-ads/ads-eltiempo.eot');
        src: url('/fonts/font-ads/ads-eltiempo.eot') format('embedded-opentype'),
            url('/fonts/font-ads/ads-eltiempo.woff2') format('woff2'),
            url('/fonts/font-ads/ads-eltiempo.woff') format('woff'),
            url('/fonts/font-ads/ads-eltiempo.ttf') format('truetype'),
            url('/fonts/font-ads/ads-eltiempo.svg') format('svg');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'DMSans-Regular';
        src: url('/fonts/DM_Sans/DMSans-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'DMSans-Medium';
        src: url('/fonts/DM_Sans/DMSans-Medium.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'DMSans-Bold';
        src: url('/fonts/DM_Sans/DMSans-Bold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    /* List popover  */
    .MuiPopover-paper .MuiMenu-list {
        padding: 0 !important;
    }
    [class^="icon-"], [class*=" icon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        font-size: 10px;
        margin-right: 4px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: textfield !important;
    margin: 0;
    -moz-appearance:textfield !important;
    }
    input[type="number"],
    input[type="number"]{
        -webkit-appearance: textfield !important;
    margin: 0;
    -moz-appearance:textfield !important;
    }
    .icon-desktop:before {
        content: "\\e900";
    }
    .icon-mobile:before {
    content: "\\e901";
    }
    .MuiDialog-paperWidthSm {
        overflow-x: hidden;
    }
`
