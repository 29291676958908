/* import external modules */
import React, { lazy, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
// import GuardedRoute from '../GuardedRoute/guardedRoute'

/* import internal modules */
const LazyUsersPage = lazy(() => import('../../pages/Users'))
const LazyAdvertiserPage = lazy(() => import('../../pages/Advertiser'))
const LazyCalendarPage = lazy(() => import('../../pages/Calendar'))
const LazyCampaingPage = lazy(() => import('../../pages/Campaigns'))
const LazyLogChangesPage = lazy(() => import('../../pages/Changes'))
const LazyMaterialsPage = lazy(() => import('../../pages/Materials'))
const LazyPricingPage = lazy(() => import('../../pages/Pricing'))
const LazyRolsPage = lazy(() => import('../../pages/Rols'))
const LazyFormatsPage = lazy(() => import('../../pages/Formats'))
const LazyAudiencePage = lazy(() => import('../../pages/Audience'))
const LazyRootPage = lazy(() => import('../Root/Root'))
const LazyLeads = lazy(() => import('../../pages/Leads'))
const LazyDiscountsPage = lazy(() => import('../../pages/Discounts'))
const LazyBannersPage = lazy(()=> import('../../pages/Banner'))
const LazyMessagesPage = lazy(()=> import('../../pages/Messages'))
/**
 *  @description This functional component is a router of all project
 *  @version 1.0.0
 *  @since 23/04/2020
 *  @author Jaime Andrés Gómez Gutiérrez <jaigom.ext@eltiempo.com>
 *
 *  @function
 *  @name Router
 *  @returns {Component} Returns a component with routes for each page project
 **/

const Router = () => {
  const [menuMap, setMenuMap] = useState({})
  const menu = useSelector(({ user }) => user.user?.permissions)

  useEffect(() => {
    if (!menu) {
      return
    }
    const map = {}
    menu.forEach(m => {
      map[m.src] = m
    })
    setMenuMap(map)
  }, [menu])

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LazyRootPage} />
        {Boolean(menuMap['/anunciantes']) && <Route exact path="/anunciantes" component={LazyAdvertiserPage} />}
        {Boolean(menuMap['/usuarios']) && <Route exact path="/usuarios" component={LazyUsersPage} />}
        {Boolean(menuMap['/formatos']) && <Route exact path="/formatos" component={LazyFormatsPage} />}
        {Boolean(menuMap['/campanas']) && <Route exact path="/campanas" component={LazyCampaingPage} />}
        {Boolean(menuMap['/calendario']) && <Route exact path="/calendario" component={LazyCalendarPage} />}
        {Boolean(menuMap['/log-cambios']) && <Route exact path="/log-cambios" component={LazyLogChangesPage} />}
        {Boolean(menuMap['/materiales']) && <Route exact path="/materiales" component={LazyMaterialsPage} />}
        {Boolean(menuMap['/precios']) && <Route exact path="/precios" component={LazyPricingPage} />}
        {Boolean(menuMap['/roles']) && <Route exact path="/roles" component={LazyRolsPage} />}
        {Boolean(menuMap['/audiencia']) && <Route exact path="/audiencia" component={LazyAudiencePage} />}
        {Boolean(menuMap['/leads']) && <Route exact path="/leads" component={LazyLeads} />}
        {Boolean(menuMap['/discount']) && <Route exact path="/discount" component={LazyDiscountsPage} />}
        {Boolean(menuMap['/banner']) && <Route exact path="/banner" component={LazyBannersPage} />}
        {Boolean(menuMap['/mensajes']) && <Route exact path="/mensajes" component={LazyMessagesPage} />}
        <Route component={LazyRootPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router
