import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {createRole, fetchRoles, removeRole, updateRole} from "./thunk";

const rolesAdapter = createEntityAdapter({
    selectId: role => role.roleId,
    sortComparer: false
});

const slice = createSlice({
    name: 'Roles',
    initialState: rolesAdapter.getInitialState({
        error: undefined,
    }),
    reducers: {
      setError(state, action) {
          state.error = action.payload;
      }
    },
    extraReducers: builder => {
        builder.addCase(fetchRoles.fulfilled, rolesAdapter.setAll);
        builder.addCase(createRole.fulfilled, rolesAdapter.addOne);
        builder.addCase(removeRole.fulfilled, rolesAdapter.removeOne);
        builder.addCase(updateRole.fulfilled, rolesAdapter.upsertOne);
    }
});

export const {setError} = slice.actions;

export const {
    selectAll: getAllRoles,
    selectById: getRoleById,
    selectIds: getAllRolesIds,
    selectEntities: getAllRoleEntities,
} = rolesAdapter.getSelectors(state => state[slice.name]);

export default slice.reducer;