import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  backdrop: (props) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    background: props.transparent ? 'rgba(255, 255, 255, 0.8)' : '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  text: {
    font: '14px/26px DMSans-Medium',
    color: '#4F5356',
    textAlign: 'center',
    marginTop: 0,
  },
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 0 0',
  },
}))

export default useStyles
