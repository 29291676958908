import axios from 'axios'
import qs from 'qs'
import { async } from 'regenerator-runtime'
import { API_ENDPOINT } from '../../../constants/apiEndpoint'
import { listCondolenceHeader, listCondolenceTitle } from '../../../constants/listCondolences'
import { AdvertisingTypeCode } from '../../Materials/Slice/constants'



export const fetchAllMessages = () => {
  const year = new Date();
  const month = year.getMonth() + 1 < 10 ? "0" + (year.getMonth() + 1) : "" + (year.getMonth() + 1);
  const startDate = `${year.getFullYear() - 1}-${month}-01T00:00:00.000Z`;
  const endDate = `${year.getFullYear()}-12-31T00:00:00.000Z`;

  const url = `${API_ENDPOINT}/campaigns`
  return axios
    .get(url, {
      params: {
        filter: {
          where: { 
            manualStatus: { neq: 0 },
            productType: { eq : 2 },
            createdAt: { gte: startDate, lte: endDate },
         },
          include: [
            {
              relation: 'advertisements',
              scope: {
                include: [
                  {
                    relation: 'resources',
                    scope: { where: { enabled: true } },
                  },
                  { relation: 'messageObt' },                  
                  { relation: 'advertisingType' },
                ],
              },
            },
            { relation: 'account' },
          ],
        },
      },
    })
    .then((r) => r.data)
}

export const getFormatsAndDisplaysObt = () => {
    return axios.get(`${API_ENDPOINT}/format-displays`, {
        params: {
            filter: {
                where: {status: {inq: [1, 2]}},
                include: [{relation: 'format'}, {relation: 'display'}],
            },
        }
    })
}

export const updateMessageStatus = async (id, typeId, status, observation)=>{
  localStorage.setItem('status-obt',status)
  const body = {
    status: status,
    observation: observation,
  }
  let response = await axios.patch(
    `${API_ENDPOINT}/message/${id}/change-state`,
    body
  )
  return response
}

export const updateMessage = async (id, body)=>{
  
  const data = {
    ceremonyDate: body.date,
    namespace: body.deceased,
    header: listCondolenceHeader.find(item => item.value == body.header)?.name,
    message: body.message,
    ceremonyHour: body.hour,
    title: listCondolenceTitle.find(item => item.value == body.titleId)?.name,
    publicName: body.publicName
  }

  let response = await axios.patch(
    `${API_ENDPOINT}/message-obts/${id}`, 
    data
  )
  return response
}