import { createAsyncThunk } from '@reduxjs/toolkit'
import * as API from './repository'
import { useSelector } from 'react-redux'
import {
  setErrorGlobalMessage,
  setSuccessGlobalMessage,
} from '../../../core/ui/ui.slice'
import { handleError } from '../../../helpers/handleError'
import messages from './../../../constants/messages'
import { selectMessagesId } from './index'
import { resourcesMessagesList, nameFormatMessagesList } from './helpers'

export const fetchMessages = createAsyncThunk(
    'Messages/fetchMessages',
    async () => {
      const materialsAndMessagesList = await API.fetchAllMessages()
      const materialsAndFormatList = await API.getFormatsAndDisplaysObt()
      const materialsAndMessagesListNew = resourcesMessagesList(materialsAndMessagesList)
      return nameFormatMessagesList(materialsAndMessagesListNew,materialsAndFormatList.data)
    }
)


export const updateCondolenceMenssage = createAsyncThunk(
    'Messages/updateCondolenceMenssage',
    async ({ mensaje, body }, thunkAPI) => {
      try {
        // update campaign status
        console.log(mensaje,body)
        await API.updateMessage(body.messageId, body)

        thunkAPI.dispatch(fetchMessages())
        thunkAPI.dispatch(setSuccessGlobalMessage())

        return {
          id: selectMessagesId(body),
          changes: { messageId: body.messageId },
        }

      } catch (error) {
        thunkAPI.dispatch(
          setErrorGlobalMessage({
            message: handleError(error),
          })
        )
        throw error
      }
    }
  )

  export const updateMessageStatus = createAsyncThunk(
    'Messages/updateMessageStatus',
    async ({ mensaje, state, observation}, thunkAPI) => {
      try {
          await API.updateMessageStatus(
            mensaje.messageId,
            mensaje.advertisingTypeId,
            state,
            observation
          )
  
        thunkAPI.dispatch(fetchMessages())
        thunkAPI.dispatch(setSuccessGlobalMessage())
        return {
          id: selectMessagesId(mensaje),
          changes: { status: state },
        }
      } catch (error) {
        thunkAPI.dispatch(
          setErrorGlobalMessage({
            message: handleError(error),
          })
        )
        throw error
      }
    }
  )