import axios from 'axios'
import { TOKEN_DB_KEY } from '../../constants/localStorage'

/** @description Calls an interceptor each time a request is called and
 *  adds the authorization token to the request headers
 *  @version 1.0.0
 *  @since 19/07/2020
 *  @author Juan Felipe Vásquez <juavas.ext@eltiempo.com>
 **/

axios.interceptors.request.use(async (config) => {
  const token = window.sessionStorage.getItem(TOKEN_DB_KEY)
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})
