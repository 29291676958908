import {createAsyncThunk} from "@reduxjs/toolkit";
import * as API from "./repository";
import {setErrorGlobalMessage, setSuccessGlobalMessage} from "../../../core/ui/ui.slice";
import {handleError} from "../../../helpers/handleError";
import {getRoleById, setError} from "./index";
import {arraySymmetricDiff} from "../../../helpers/arrays";

export const fetchRoles = createAsyncThunk(
    'Roles/fetchRoles',
    (where) => API.fetchAllRoles(where)
);

export const createRole = createAsyncThunk(
    'Roles/createRole',
    async ({modules, role: {name, status}}, thunkAPI) => {
        try {
            const roleCreated = await API.createRole({name, status});
            const permissionByRoleOnModules = await Promise.all(
                modules.map(({moduleId, permissionId}) => API.assignPermissionByRoleOnModule({
                    moduleId,
                    permissionId,
                    roleId: roleCreated.roleId
                }))
            );

            thunkAPI.dispatch(setSuccessGlobalMessage());
            return {
                ...roleCreated,
                permissionByRoleOnModules
            }
        } catch (error) {
            thunkAPI.dispatch(setErrorGlobalMessage({
                message: handleError(error),
            }));
            throw error;
        }
    }
);

export const updateRole = createAsyncThunk(
    'Roles/updateRole',
    async ({roleId, name, modules}, thunkAPI) => {
        try {
            const oldRole = getRoleById(thunkAPI.getState(), roleId);
            const {permissionByRoleOnModules: currentPermissions} = oldRole;
            const {aDiff: add, bDiff: remove} = arraySymmetricDiff(
                modules,
                currentPermissions,
                (a, b) => b.moduleId === a.moduleId && b.permissionId === a.permissionId
            );
            await Promise.all([
                // crear permisos
                ...add.map(API.assignPermissionByRoleOnModule),
                // eliminar permisos
                ...remove.map(({permissionByRoleOnModuleId}) => {
                    return API.deletePermissionByRoles(permissionByRoleOnModuleId);
                })
            ]);
            if (oldRole.name !== name) {
                // actualizar rol
                await API.updateRole({roleId, name});
            }

            thunkAPI.dispatch(setSuccessGlobalMessage());
            // consultar rol
            return await API.fetchRoleById(roleId);
        } catch (error) {
            thunkAPI.dispatch(setErrorGlobalMessage({
                message: handleError(error),
            }));
            throw error;
        }
    }
)

export const removeRole = createAsyncThunk(
    'Roles/removeRole',
    async (roleId, thunkAPI) => {
        try {
            await API.removeRole(roleId);
            thunkAPI.dispatch(setSuccessGlobalMessage());
            return Number(roleId);
        } catch (error) {
            if (error.isAxiosError && error.response.status === 409) {
                thunkAPI.dispatch(setError(true));
            } else {
                thunkAPI.dispatch(setErrorGlobalMessage({
                    message: handleError(error),
                }));
            }
            throw error;
        }
    }
)