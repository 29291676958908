export const resourcesList = (list) => {
  let resource = []
  list.forEach((i) => {
    if (i.advertisements) {
      // console.log(i,'<AQUI> advertisements 2');
      i.advertisements.forEach((j) => {
        if ( (j.advertisingType.code === 'CPM' || j.advertisingType.code === 'DMP')) { 
          resource.push({
            campaignId: i.campaignId,
            name: `${i.account.name} ${i.account.surname}`,
            sort: j.sort,
            report: j.report,
            mathilde: j.mathilde ? j.mathilde : 0,
            advertisingId: j.advertisingId,
            advertisingSmartTrackingLink: j.smartTrackingLink,
            advertisingTypeName: j.advertisingType.name,
            advertisingTypeId: j.advertisingTypeId,
            resourceId: j.resources[0].resourceId,
            resourceSrc: j.resources[0].src,
            resourcePrueba: 'prueba',
            resourceStatus: j.resources[0].status,
            resourceCreated: j.resources[0].createdAt,
            resourceUpdated: j.resources[0].updatedAt,
          })
        }
        if( (j.advertisingType.code === 'CPC')){
          resource.push({
            campaignId: i.campaignId,
            name: `${i.account.name} ${i.account.surname}`,
            sort: j.sort,
            report: j.report,
            mathilde: j.mathilde ? j.mathilde : 0,
            advertisingId: j.advertisingId,
            advertisingSmartTrackingLink: j.smartTrackingLink,
            advertisingTypeName: j.advertisingType.name,
            advertisingTypeId: j.advertisingTypeId,
            resourceId: j.resourcesClick[0].resourceId,
            resourceSrcRobapagina: j.resourcesClick[0].src_robapagina,
            resourceSrcBilborad: j.resourcesClick[0].src_bilborad ? j.resourcesClick[0].src_bilborad : '',
            resourceSrcBanner: j.resourcesClick[0].src_banner ? j.resourcesClick[0].src_banner : '',
            resourceSrcCatfist: j.resourcesClick[0].src_catfist ? j.resourcesClick[0].src_catfist : '',
            resourceStatus: j.resourcesClick[0].status,
            resourceStatusRobapagina: j.resourcesClick[0].statusRobapagina,
            resourceStatusBilborad: j.resourcesClick[0].statusBilborad,
            resourceStatusBanner: j.resourcesClick[0].statusBanner,
            resourceStatusCatfist: j.resourcesClick[0].statusCatfist,
            resourceCreated: j.resourcesClick[0].createdAt,
            resourceUpdated: j.resourcesClick[0].updatedAt,
          })
        }
        if((j.advertisingType.code === 'OBT')){
          resource.push({
            campaignId: i.campaignId,
            name: `${i.account.name} ${i.account.surname}`,
            sort: j.sort,
            report: j.report,
            mathilde: j.mathilde ? j.mathilde : 0,
            advertisingId: j.advertisingId,
            advertisingSmartTrackingLink: j.smartTrackingLink,
            advertisingTypeName: j.advertisingType.name,
            advertisingTypeId: j.advertisingTypeId,
            resourceId: j.resources[0].resourceId,
            resourceSrc: j.resources[0].src,
            resourcePrueba: 'prueba',
            resourceStatus: j.resources[0].status,
            resourceCreated: j.resources[0].createdAt,
            resourceUpdated: j.resources[0].updatedAt,
          })
        }
      })
    }
    // console.log(resource, 'resource');
    if (i.adSponsoredContents) {
      // console.log(i,'<AQUI> adSponsoredContents 3');
      i.adSponsoredContents.forEach((j) => {
        if (j.adSponsoredContentPricingAdContents) {
          j.adSponsoredContentPricingAdContents.forEach((k) => {
            resource.push({
              campaignId: i.campaignId,
              name: `${i.account.name} ${i.account.surname}`,
              sort: j.sort,
              report: j.report,
              advertisingId: j.adSponsoredContentId,
              advertisingSmartTrackingLink: '',
              advertisingTypeName:
                k.pricingAdContent.advertisingTypeDigitalProperties
                  .advertisingType.name,
              advertisingTypeId:
                k.pricingAdContent.advertisingTypeDigitalProperties
                  .advertisingTypeId,
              resourceId: j.resourcesContents[0].ResourceContentId,
              resourceSrc: j.resourcesContents[0].src,
              resourcePrueba: 'prueba',
              resourceStatus: j.resourcesContents[0].status,
              resourceCreated: j.resourcesContents[0].createdAt,
              resourceUpdated: j.resourcesContents[0].updateAt,
              title: j.title,
              lead: j.lead,
              paragraph: j.paragraph,
            })
          })
        }
      })
    }
  }
  )
  resource = resource.sort((a, b) => {
    return a.campaignId - b.campaignId || a.sort - b.sort
  })
  
  
  return resource
}

// export const resourceCondolence = () =>{

// }
