// import internal modules
import {configureStore} from "@reduxjs/toolkit";
import {configurePersist} from "./persist";
import rootReducer from "../reducers/rootReducer";
import {persistStore} from "redux-persist";
import UIMiddlewares from "../../core/ui/ui.middleware";

const persists = configurePersist(rootReducer);

export const store = configureStore({
    reducer: persists.reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    })
        .concat(UIMiddlewares)
});

export const persistor = persistStore(store);