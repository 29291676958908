const message = {
  titleRoles: 'Roles',
  btnCreateRoles: 'Crear rol',
  btnCreateDiscounts: 'Crear Cupon',
  btnCreateBanner : 'Crear Banner',
  titleUsers: 'Usuarios',
  titleCampaigns: 'Campañas',
  titleMaterials: 'Materiales',
  titleAdvertiser: 'Anunciantes',
  titleLeads: 'Leads',
  titleDiscounts: 'Codigo Descuento',
  titleBanner: 'Banners',
  titleMessages: 'Mensajes',
  titleCondolence: 'Condolencias',

  // When fill form format
  messageEmptyNameFormat: 'Escribe el nombre del formato',
  messageEmptyWidth: 'Escribe el ancho en pixeles',
  messageEmptyHeight: 'Escribe el alto en pixeles',
  messageEmptyWeight: 'Escribe el peso máximo en kb',
  messageEmptyDevice: 'Selecciona un dispositivo',
  messageEmptyPortal: 'Selecciona un portal',
  messageEmptyCities: 'Selecciona una ciudad',
  messageEmptyImage: 'Debes subir una imagen',
  messageIncorrectFormat: 'Formato incorrecto',

  // Alert changes
  saveChanges: 'Los cambios se guardaron exitosamente',

  messageEmptyRejectDescription:
    'Escribe las razones por las cuales esta pauta debe ser rechazada',
  messageEmptyCheckboxRoles:
    'Debes seleccionar al menos un permiso para crear un nuevo rol',
  messageUnauthorized:
    'Este usuario no está registrado o no tiene permisos. Contacta al administrador',

  // Pdf validation alerts
  messageValidationErrorPdf: 'El archivo debe estar en .pdf',
  messageErrorUploadingPdf: 'No se pudo subir el reporte',
  messageDefaultSponsoredTitle: 'CONTENIDO PATROCINADO',
}
export const statusSuccess = {
  message: 'Los cambios se guardaron exitosamente',
  icon: 'check',
  status: 'success',
}

export const statusError = {
  icon: 'close',
  status: 'error',
}

export default message
