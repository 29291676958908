import { createAsyncThunk } from '@reduxjs/toolkit'
import * as API from './repository'
import {
  setErrorGlobalMessage,
  setSuccessGlobalMessage,
} from '../../../core/ui/ui.slice'
import { handleError } from '../../../helpers/handleError'
import { getRoleById } from '../../Roles/Slice'
import { getUserById } from '.'
import { isEqual } from '../../../helpers/isEqual'

export const fetchUsers = createAsyncThunk('Users/fetchUsers', () =>
  API.fetchAllUsers()
)

export const createUser = createAsyncThunk(
  'Users/createUser',
  async (user, thunkAPI) => {
    try {
      const response = await API.createUser({ ...user, status: 1 })
      thunkAPI.dispatch(setSuccessGlobalMessage())
      return {
        ...response,
        role: getRoleById(thunkAPI.getState(), user.roleId),
      }
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)

export const updateUser = createAsyncThunk(
  'Users/updateUser',
  async ({ user, body }, thunkAPI) => {
    try {
      const oldUser = getUserById(thunkAPI.getState(), user.userId)
      const currentUser = { ...user, ...body }
      if (!isEqual(oldUser, currentUser)) {
        await API.updateUser(user.userId, body)
        const response = await API.fetchUserById(user.userId)
        thunkAPI.dispatch(setSuccessGlobalMessage())
        if (oldUser.roleId !== currentUser.roleId) {
          return {
            ...response,
            role: getRoleById(thunkAPI.getState(), body.roleId),
          }
        }
        return response
      }
      return currentUser
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)

export const deleteUser = createAsyncThunk(
  'Users/deleteUser',
  async (userId, thunkAPI) => {
    try {
      await API.deleteUser(userId)
      thunkAPI.dispatch(setSuccessGlobalMessage())
      return Number(userId)
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)
