import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { fetchLeads } from './thunk'

const leadsAdapter = createEntityAdapter({
  selectId: (lead) => lead.leadId,
  sortComparer: false,
})

const slice = createSlice({
  name: 'Leads',
  initialState: leadsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(fetchLeads.fulfilled, leadsAdapter.setAll)
  },
})

export const {
  selectAll: getAllLeads
} = leadsAdapter.getSelectors((state) => state[slice.name])

export default slice.reducer
