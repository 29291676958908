import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";
import {statusError, statusSuccess} from "../../constants/messages";
// Slice
const slice = createSlice({
    name: 'ui',
    initialState: {
        loading: false,
        message: undefined
    },
    reducers: {
        showGlobalMessage: (state, {payload}) => {
            state.message = payload;
        },
        hideGlobalMessage: (state) => {
            state.message = undefined;
        },
        showLoader: (state) => {
            state.loading = true;
        },
        hideLoader: (state) => {
            state.loading = false;
        }
    }
});

// actions
export const {showGlobalMessage, hideGlobalMessage, showLoader, hideLoader} = slice.actions;

export const setSuccessGlobalMessage = createAction(
    'UI/showGlobalMessage/success',
    (message = {}) => ({payload: {...message, ...statusSuccess}})
);

export const setErrorGlobalMessage = createAction(
    'UI/showGlobalMessage/error',
    (payload = {}) => ({payload: {...payload, ...statusError}})
);


// selectors
export const isLoading = createSelector(
    state => state.loading.loading || state.UI.loading,
    (loading) => loading
);

export const getLegacyGlobalMessage = createSelector(
    state => state.globalMessages.messages,
    v => v
);

export const getGlobalMessage = createSelector(
    state => state.UI.message,
    (value) => value
);

export const getMixedGlobalMessage = createSelector(
    [getGlobalMessage, getLegacyGlobalMessage],
    (gm, lgm) => gm ?? lgm
);

export const hasGlobalMessage = createSelector(
    [getGlobalMessage, getLegacyGlobalMessage],
    (gm, lgm) => {
        return Boolean(gm) || Boolean(lgm.open)
    }
);


export default slice.reducer;