// Import types
import { SET_DIGITAL_PROPERTIES } from "../../actions/types/types"

const initialState = {
  digitalProperties: [],
}

/**
 * Digital properties reducer
 * @param {*} state
 * @param {*} action
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DIGITAL_PROPERTIES:
      return Object.assign({}, state, {
        digitalProperties: action.payload.digitalProperties,
      })
    default:
      return state
  }
}

export default reducer
