/* eslint-disable array-callback-return */
// import internal modules
import {
  FETCH_PRICING_SUCCESS,
  FETCH_CONTENT_PRICING_SUCCESS,
  FETCH_PRICING_ERROR,
  FETCH_FORMATS_BY_DISPLAY_SUCCESS,
  FETCH_FORMATS_BY_DISPLAY_ERROR,
  FETCH_PORTALS_SUCCESS,
  FETCH_PORTALS_ERROR,
  FETCH_NEW_PRICING_SUCCESS,
  FETCH_NEW_CONTENT_PRICING_SUCCESS,
  FETCH_NEW_PRICING_ERROR,
  FETCH_DELETE_PRICING_SUCCESS,
  FETCH_DELETE_CONTENT_PRICING_SUCCESS,
  FETCH_DELETE_PRICING_ERROR,
  FETCH_EDIT_PRICING_SUCCESS,
  FETCH_EDIT_CONTENT_PRICING_SUCCESS,
  FETCH_EDIT_PRICING_ERROR,
  FETCH_CITIES_SUCCESS,
} from '../../actions/types/types'

const initialState = {
  pricing: [],
  contentPricing: [],
  formatsByDisplay: [],
  portals: [],
  cities: [],
  editPricing: [],
  isFetching: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRICING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        pricing: action.payload.pricing.data,
      }
    case FETCH_CONTENT_PRICING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        contentPricing: action.payload.pricing.data,
      }
    case FETCH_EDIT_PRICING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        editPricing: action.payload.pricing.data,
      }
    case FETCH_EDIT_CONTENT_PRICING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        editPricing: action.payload.pricing.data,
      }
    case FETCH_FORMATS_BY_DISPLAY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        formatsByDisplay: action.payload.formatsByDisplay.data,
      }
    case FETCH_PORTALS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        portals: action.payload.portals.data,
      }
    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        cities: action.payload.cities.data,
      }
    case FETCH_NEW_PRICING_SUCCESS:
      let newPricing = state.pricing.slice()
      newPricing.push(action.payload.newPricing.data)
      return {
        ...state,
        isFetching: false,
        pricing: newPricing,
      }
    case FETCH_NEW_CONTENT_PRICING_SUCCESS:
      let newContentPricing = state.contentPricing.slice()
      newContentPricing.push(action.payload.newPricing.data)
      return {
        ...state,
        isFetching: false,
        contentPricing: newContentPricing,
      }
    case FETCH_DELETE_PRICING_SUCCESS:
      const idToRemove = action.payload.deletePricing

      let removeItemToPricing = state.pricing.filter((item) => {
        if (item.formatBySectionOnDisplayId !== idToRemove) return true
      })

      return {
        ...state,
        isFetching: false,
        pricing: removeItemToPricing,
      }
    case FETCH_DELETE_CONTENT_PRICING_SUCCESS:
      const idRemovePricing = action.payload.deletePricing

      let removeItemToContentPricing = state.contentPricing.filter((item) => {
        if (item.pricingAdContentId !== idRemovePricing) return true
      })

      return {
        ...state,
        isFetching: false,
        contentPricing: removeItemToContentPricing,
      }
    case FETCH_PRICING_ERROR:
    case FETCH_FORMATS_BY_DISPLAY_ERROR:
    case FETCH_PORTALS_ERROR:
    case FETCH_NEW_PRICING_ERROR:
    case FETCH_DELETE_PRICING_ERROR:
    case FETCH_EDIT_PRICING_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
