export const CampaignDateStatus = {
  NotStarted: 'Sin iniciar',
  InProgress: 'En curso',
  Finished: 'Finalizada',
}

export const CampaignDateStatusCode = {
  NotStarted: 0,
  InProgress: 1,
  Finished: 2,
}

export const CampaignMaterialStatus = {
  Rejected: 'Rechazada',
  Approved: 'Aceptada',
  Pending: 'Pendiente',
}

export const CampaignMaterialStatusCode = {
  Rejected: 0,
  Approved: 1,
  Pending: 2,
}

export const CampaignManualStatus = {
  Pending: 'Pendiente',
  Active: 'Activa',
  Canceled: 'Cancelada',
}

export const CampaignStatusCode = {
  Pending: 0,
  Active: 1,
  Canceled: 2,
}
export const Unassigned = 'Sin asignar'
