import {FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE} from "redux-persist";
import storage from 'redux-persist/lib/storage'

export function configurePersist(rootReducer) {
    const persistConfig = {
        key: 'Administrador | Autoservicio de pauta',
        storage,
        version: 1,
        whitelist: ['user'], // reducer to persist
    };
    return {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        reducer: persistReducer(persistConfig, rootReducer),
    };
}