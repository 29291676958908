/* import external modules */
import React from 'react'

/* import internal modules */
import { ButtonMain } from './styles'
import Icon from '../Icon'
import useStyles from './styles'
import Img from '../Img'

const Button = ({
  className,
  nameIcon,
  colorIcon,
  iconButton,
  startIcon,
  endIcon,
  onClick,
  title,
  iconWidth,
  iconHeight,
  imgButton,
  imgSrc,
  imgWidth,
  titleButton,
  ...props
}) => {
  const classes = useStyles(props)
  return (
    <ButtonMain
      type="submit"
      className={className || classes.ButtonMain}
      endIcon={
        endIcon ? <Icon icon={nameIcon} color={colorIcon || '#001634'} /> : null
      }
      startIcon={startIcon ? <Icon icon={nameIcon} color={colorIcon} /> : null}
      onClick={onClick}
      title={titleButton}
      {...props}>
      {title}
      {iconButton && (
        <Icon
          icon={nameIcon}
          color={colorIcon}
          width={iconWidth ? iconWidth : null}
          height={iconHeight ? iconHeight : null}
        />
      )}
      {imgButton && <Img src={imgSrc} width={imgWidth ? imgWidth : null}></Img>}
    </ButtonMain>
  )
}

export default Button
