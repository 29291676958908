import {
    DISCOUNT_VALUE
  } from '../../actions/types/types'

  const initialState = {
    status: 0,
  }

  const DiscountValue = (state = initialState, action) => {
    switch (action.type) {
        case DISCOUNT_VALUE:
        return {
            ...state,
            status: action.payload.discounts.data,
        }

        default:
        return state
    }
  }

  export default DiscountValue