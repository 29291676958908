// import external modules
import { combineReducers } from '@reduxjs/toolkit'

// import internal modules
import user from './user/user'
import pricing from './pricing/pricing'
import formats from './formats/formats'
import displays from './displays/displays'
import digitalProperties from './digitalProperties/digitalProperties'
import resources from './resources/resources'
import globalMessages from './globalMessages/globalMessages'
import loading from './loading/loading'
import audience from './audience/audiences'
import roleReducer from '../../components/Roles/Slice'
import modulesReducer from '../../core/entities/modules'
import userReducer from '../../components/Users/Slice'
import advertisersReducer from '../../components/Advertiser/Slice'
import uiReducer from '../../core/ui/ui.slice'
import condolence from '../../core/entities/modules/routCondolence'
import campaignsReducer from '../../components/Campaigns/Slice'
import materialsReducer from '../../components/Materials/Slice'
import leadsReducer from '../../components/Leads/Slice'
import discountsReducer from '../../components/Discount/Slice'
import DiscountValue from '../reducers/discount/discounts'
import Banner from '../reducers/banner/banners'
import bannersReducer from '../../components/Banner/Slice'
import messagesReducer from '../../components/Message/Slice'


const rootReducer = combineReducers({
  Roles: roleReducer,
  Modules: modulesReducer,
  Messages: messagesReducer,
  UI: uiReducer,
  Users: userReducer,
  Campaigns: campaignsReducer,
  Materials: materialsReducer,
  Advertisers: advertisersReducer,
  Leads: leadsReducer,
  Discounts: discountsReducer,
  DiscountsRead: DiscountValue,
  BannersRead:  Banner,
  Banners: bannersReducer,
  user,
  pricing,
  formats,
  displays,
  digitalProperties,
  resources,
  globalMessages,
  loading,
  audience,
  OBT: condolence,
})

export default rootReducer
