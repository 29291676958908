import axios from "axios";
import {API_ENDPOINT} from "../../../constants/apiEndpoint";

const ModuleRepository = {
    fetchAllModules() {
        return axios.get(`${API_ENDPOINT}/modules`)
            .then(response => response.data);
    }
}

export default ModuleRepository;