import {
    SET_BANNER
  } from '../../actions/types/types'

  const initialState = {
    status: 0,
  }

  const Banner = (state = initialState, action) => {
    switch (action.type) {
        case SET_BANNER:
        return {
            ...state,
            status: action.payload.banners.data,
        }

        default:
        return state
    }
  }

  export default Banner