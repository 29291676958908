import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { fetchAdvertisers } from './thunk'

const advertisersAdapter = createEntityAdapter({
  selectId: (account) => account.accountId,
  sortComparer: false,
})

const slice = createSlice({
  name: 'Advertisers',
  initialState: advertisersAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(fetchAdvertisers.fulfilled, advertisersAdapter.setAll)
  },
})

export const { selectAll: getAllAdvertisers } = advertisersAdapter.getSelectors(
  (state) => state[slice.name]
)

export default slice.reducer
