import { createSelector } from '@reduxjs/toolkit'
import { getAllUsers } from '../../Users/Slice/'

export const selectUsersByRoleId = (roleId) =>
  createSelector(getAllUsers, (user) => {
    return user ? user.filter((u) => u.role.roleId === roleId) : []
  })

export const selectUserDataById = (state, userId) =>
  createSelector(getAllUsers, (user) => {
    return user?.find((u) => u.userId === userId)
  })(state)
