/**
 * @typedef {function} ComparatorFn
 * @param a
 * @param b
 * @return {boolean}
 */

/**
 * Halla la intersección entre dos conjuntos
 * @param {array} a Conjunto A
 * @param {array} b Conjunto B
 * @param {ComparatorFn} comparatorFn Comparador que evalúa si el elemento del conjunto A **si** esta en B
 * @returns {*}
 */
export function arrayIntersection(a, b, comparatorFn) {
    const comparator = comparatorFn ?? ((a, b) => a === b);
    return a.filter((ai) => b.some(bi => comparator(ai, bi)));
}

/**
 * Hallar la diferencia entre dos conjuntos
 * @param {array} a Conjunto A
 * @param {array} b Conjunto B
 * @param {ComparatorFn} comparatorFn Comparador que evalúa si el elemento del conjunto A **no** esta en B
 * @returns {array}
 */
export function arrayDiff(a, b, comparatorFn) {
    return a.filter(ai => !b.some(bi => comparatorFn(ai, bi)))
}

/**
 * Hallar la diferencia simétrica entre dos conjuntos
 * @param {array} a Conjunto A
 * @param {array} b Conjunto B
 * @param {ComparatorFn} comparatorFn Comparador que evalúa si el elemento del conjunto A **no** esta en B y viceversa
 * @return {{aDiff: array, bDiff: array}} se entregan las diferencias separadas por conjunto respecto al otro
 */
export function arraySymmetricDiff(a, b, comparatorFn) {
    const aDiff = arrayDiff(a, b, comparatorFn);
    const bDiff = arrayDiff(b, a, comparatorFn);
    return {aDiff, bDiff};
}

