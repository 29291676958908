import {
  SET_FETCHING_RESOURCES,
  SET_RESOURCES,
  SET_RESOURCE_ASSET,
  SET_RESOURCE_STATUS,
} from '../../actions/types/types'

const initialState = {
  resources: [],
  fetching: false,
}

const resourcesReducer = (state = initialState, action) => {
  let data = null
  let newResourcesList = null
  switch (action.type) {
    case SET_RESOURCES:
      return Object.assign({}, state, { resources: action.payload.resources })
    case SET_RESOURCE_STATUS:
      data = action.payload.data
      newResourcesList = state.resources.map((r) =>
        r.resourceId === data.id
          ? { ...r, status: data.status, observation: data.observation }
          : r
      )
      return Object.assign({}, state, { resources: newResourcesList })
    case SET_RESOURCE_ASSET:
      newResourcesList = state.resources.map((r) =>
        r.resourceId === data.id ? { ...r, src: data.asset } : r
      )
      return Object.assign({}, state, { resources: newResourcesList })
    case SET_FETCHING_RESOURCES:
      return Object.assign({}, state, { fetching: action.payload.fetching })
    default:
      return state
  }
}

export default resourcesReducer
