/* import external modules */
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paper: (props) => ({
    width: props.width ? `${props.width}px` : '370px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '45px 30px',
  }),
  title: {
    padding: '5px 0 0 0',
    font: '18px/26px DMSans-Medium',
    color: '#4F5356',
  },
  content: (props) => ({
    padding: props.paddingContent ? `${props.paddingContent}` : '20px 0 40px 0',
    color: '#4F5356',
    font: '14px/22px DMSans-Regular',
    overflowY: 'initial',
  }),
  actions: {
    padding: '10px 0 0 0',
    justifyContent: 'center',
  },
  buttomClose: {
    color: '#006CA5',
    font: '14px/18px DMSans-Regular',
    textTransform: 'inherit',
    position: 'absolute',
    border: 'none',
    top: '10px',
    right: '8px',
    boxShadow: 'none',
    background: '#FFFFFF',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
  buttom: {
    width: 'auto',
    color: '#006CA5',
    font: "500 14px/18px 'Heebo', sans-seri",
    textTransform: 'inherit',
    border: 'none',
    boxShadow: 'none',
    background: '#FFFFFF',
    '&:hover': {
      background: '#FFFFFF',
      textDecoration: 'underline',
    },
  },
  buttons: {
    justifyContent: 'center',
    padding: '0',
  },
}))

export default useStyles
