//Internal modules
import React from 'react'
import ReactDOM from 'react-dom'

//External modules
import { PersistGate } from 'redux-persist/integration/react'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import {Provider} from "react-redux";

import {store, persistor} from "./redux/storeConfig/store";


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} >
            <App />
        </PersistGate>
    </Provider>
    ,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
    