import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {fetchModules} from "./thunk";

const modulesAdapter = createEntityAdapter({
    selectId: model => model.moduleId,
    sortComparer: false
});

const slice = createSlice({
    name: 'Modules',
    initialState: modulesAdapter.getInitialState(),
    extraReducers: builder => {
        builder.addCase(fetchModules.fulfilled, modulesAdapter.setAll);
    },
});

export const {
    selectAll: getAllModules,
} = modulesAdapter.getSelectors(state => state[slice.name]);

export default slice.reducer;