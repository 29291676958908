import axios from 'axios'
import { API_ENDPOINT } from '../../../constants/apiEndpoint'

/**
 * Get Leads from API
 * @returns {response} response axios
 */
export const fetchAllDiscounts = () => {
  const url = `${API_ENDPOINT}/discounts`
  return axios.get(url).then((r) => r.data)
}


export const createDiscount = (body) => {
  return axios.post(`${API_ENDPOINT}/discounts`, body)
}

export const deleteDiscount = (discountId) => {
  const url = `${API_ENDPOINT}/discounts/${discountId}`
  return axios.delete(url)
}

export const updateDiscount = (discountId,body) =>{
  const url = `${API_ENDPOINT}/discounts/${discountId}`
  return axios.patch(url,body)
}

export const patchDiscount = (discountId,status) =>{
  return axios.patch(`${API_ENDPOINT}/discounts/${discountId}`,{ status})
}