// import internal modules
import { HANDLE_USER, SET_TOKEN, SET_USERS } from '../../actions/types/types'

const initialState = {
  user: null,
  users: [],
  token: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case HANDLE_USER:
      return Object.assign({}, state, { user: action.payload.user })
    case SET_TOKEN:
      return Object.assign({}, state, { token: action.payload.token })
    case SET_USERS:
      return Object.assign({}, state, { users: action.payload.users })
    default:
      return state
  }
}
