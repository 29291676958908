export const listCondolenceTitle = [
    {
      value: 1,
      name: ' ',
    },{
      value: 2,
      name: 'El señor',
    },
    {
      value: 3,
      name: 'La señora',
    },
  ]

  export const listCondolenceHeader = [
    {
        value: 1,
      name: 'Descansó en paz'
    },
    {
        value: 2,
      name: 'Descansó en la paz del Señor'
    },
    {
        value: 3,
      name: 'Estará siempre con nosotros'
    },
  ]
  
