import {createAsyncThunk} from "@reduxjs/toolkit";
import ModuleRepository from "./repository";

export const fetchModules = createAsyncThunk(
    'Modules/fetchModules',
    () => ModuleRepository.fetchAllModules()
)

export const fetchCondolence = createAsyncThunk(
    'OBT/fetchModules',
    () => ModuleRepository.fetchAllModules()
)

