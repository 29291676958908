import axios from 'axios'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

/**
 * Get info about auth user
 * @param {*} token
 */
export const getAuthInfo = (token) => {
  const body = {
    token,
  }
  const url = `${API_ENDPOINT}/auth`
  return axios.post(url, body, {
    headers: { 'Content-Type': 'application/json' },
  })
}
