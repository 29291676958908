import {isAnyOf, isAsyncThunkAction, isFulfilled, isPending, isRejected} from "@reduxjs/toolkit";
import {
    hideGlobalMessage,
    hideLoader,
    setErrorGlobalMessage,
    setSuccessGlobalMessage,
    showGlobalMessage,
    showLoader
} from "./ui.slice";

const debounceFactory = (delay = 300) => {
    let timer;
    return fn => {
        clearTimeout(timer);
        timer = setTimeout(() => fn(), delay)
    }
}

const debounce = debounceFactory(500);

const uiLoaderInterceptor = storeAPI => next => action => {
    if (isAsyncThunkAction(action)) {
        if (isPending(action)) {
            if (!storeAPI.getState().UI.loading) {
                storeAPI.dispatch(showLoader());
            }
            next(action);
        }

        if (isFulfilled(action) || isRejected(action)) {
            next(action);
            debounce(() => storeAPI.dispatch(hideLoader()));
        }
    } else {
        next(action);
    }
}

const uiGlobalMessageInterceptor = storeAPI => next => action => {
    if (isAnyOf(setSuccessGlobalMessage, setErrorGlobalMessage)(action)) {
        next(showGlobalMessage(action.payload));
        setTimeout(() => {
            storeAPI.dispatch(hideGlobalMessage());
        }, 5000);
    } else {
        return next(action);
    }
}

const UIMiddlewares = [uiLoaderInterceptor, uiGlobalMessageInterceptor];
export default UIMiddlewares;