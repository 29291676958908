import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { fetchDiscounts } from './thunk'

const discountsAdapter = createEntityAdapter({
  selectId: (discount) => discount.discountId,
  sortComparer: false,
})

const slice = createSlice({
  name: 'Discounts',
  initialState: discountsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(fetchDiscounts.fulfilled, discountsAdapter.setAll)
  },
})

export const {
  selectAll: getAllDiscounts
} = discountsAdapter.getSelectors((state) => state[slice.name])

export default slice.reducer
