/* import external modules */
import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core'

/* import internal modules */
import Button from '../Button'
import useStyles from './styles'

const Modal = ({
  open,
  onClose,
  children,
  title,
  onAction,
  titleAction,
  titleCancel,
  scroll,
  hideCancelButton,
  ...props
}) => {
  const classes = useStyles(props)
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={scroll ? scroll : 'paper'}
      maxWidth={props.width && 'md'}>
      <div className={classes.paper}>
        <Button
          disableRipple
          disableFocusRipple
          endIcon
          title="Cerrar"
          nameIcon="close"
          colorIcon="#006CA5"
          onClick={onClose}
          className={classes.buttomClose}
        />
        {title && (
          <DialogTitle className={classes.title} disableTypography>
            {title}
          </DialogTitle>
        )}

        <DialogContent className={classes.content}>{children}</DialogContent>
        {onAction && (
          <DialogActions className={classes.buttons}>
            {!hideCancelButton && (
              <Button
                disableRipple
                disableFocusRipple
                title={titleCancel ? `${titleCancel}` : `Cancelar`}
                onClick={onClose}
                className={classes.buttom}
              />
            )}

            <Button
              primary="true"
              title={titleAction ? titleAction : title}
              onClick={onAction}
              data-cy-accept-button="accept-button"
            />
          </DialogActions>
        )}
      </div>
    </Dialog>
  )
}

export default Modal
