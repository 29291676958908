export const RoleStatus = {
  Inactive: 0,
  ActiveVisible: 1,
  ActiveHidden: 2,
  ImmutableVisible: 3,
  ImmutableHidden: 4,
}

export const Permissions = {
  READ: 2,
  WRITE: 1,
}

export const RoleTypes = {
  Traffiker: 3,
}
