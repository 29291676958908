import axios from 'axios';
import {RoleStatus} from "./constants";
import {API_ENDPOINT} from "../../../constants/apiEndpoint";

/**
 * Get roles from API
 * @param {object} where where conditions
 * @returns {response} response axios
 */
export const fetchAllRoles = (where) => {
    return axios.get(
        `${API_ENDPOINT}/roles`,
        {
            params: {
                filter: {
                    where,
                    include: [
                        {relation: "permissionByRoleOnModules"}
                    ]
                }
            }
        }
    ).then(r => r.data)
}


export const fetchRoleById = (roleId) => {
    return axios.get(
        `${API_ENDPOINT}/roles/${roleId}`,
        {
            params: {
                filter: {
                    include: [
                        {relation: "permissionByRoleOnModules"}
                    ]
                }
            }
        }
    ).then(r => r.data)
}

export const createRole = (role) => {
    return axios.post(
        `${API_ENDPOINT}/roles`,
        role
    ).then(r => r.data)
}

export const updateRole = ({roleId, name}) => {
    return axios.patch(`${API_ENDPOINT}/roles/${roleId}`, {name})
        .then(r => r.data)
}

export const assignPermissionByRoleOnModule = ({moduleId, permissionId, roleId}) => {
    return axios.post(
        `${API_ENDPOINT}/permission-by-role-on-modules/`,
        {status: 1, roleId, permissionId, moduleId}
        )
        .then(response => response.data);
}

export const deletePermissionByRoles = (permissionByRoleOnModuleId) => {
    return axios.delete(
        `${API_ENDPOINT}/permission-by-role-on-modules/${permissionByRoleOnModuleId}`
    )
}

export const removeRole = (roleId) => {
    return axios.patch(`${API_ENDPOINT}/roles/${roleId}`, {status: RoleStatus.Inactive});
}