import axios from 'axios'
import { API_ENDPOINT } from '../../../constants/apiEndpoint'

/**
 * Get Leads from API
 * @returns {response} response axios
 */
export const fetchAllBanners = () => {
  const url = `${API_ENDPOINT}/banners`
  return axios.get(url).then((r) => r.data)
}


export const createBanner = (body) => {
  return axios.post(`${API_ENDPOINT}/banners`, body)
}

export const createBannerFlow = (body) => {
  const options = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  }
  return axios.post(`${API_ENDPOINT}/banners-flow`, body, options)
}

export const deleteBanner= (bannerId) => {
  const url = `${API_ENDPOINT}/banners/${bannerId}`
  return axios.delete(url)
}

export const updateBanner = (bannerId,body) =>{
  const options = {
    header: {
       'content-type' : 'multipart/form-data'
    }
  }
  return axios.patch(`${API_ENDPOINT}/banners-flow/${bannerId}`, body, options)
  // const url = `${API_ENDPOINT}/banners/${bannerId}`
  // return axios.patch(url,body)
}

export const patchBanner = (bannerId,status) =>{
  return axios.patch(`${API_ENDPOINT}/banners/${bannerId}`,{ status})}