// import types
import { SET_FORMATS, SET_FETCHING_FORMATS, SET_FORMAT_STATUS } from "../../actions/types/types"

// State by default
const initialState = {
  formats: [],
}

/**
 * Reducer to change format state
 */
const reducer = (state = initialState, action) => {
  let status
  let id
  switch (action.type) {
    case SET_FORMATS:
      return Object.assign({}, state, { formats: action.payload.formats })
    case SET_FETCHING_FORMATS:
      return Object.assign({}, state, { fetching: action.payload.fetching })
    case SET_FORMAT_STATUS:
      status = action.payload.status
      id = action.payload.id
      const formats = status === 0 
        ? state.formats.filter((format) => format.formatDisplayId !== id)
        : state.formats.map((format) => format.formatDisplayId === id ? ({ ...format, status }) : format)
      return Object.assign({}, state, { formats })
    default:
      return state
  }
}

export default reducer
