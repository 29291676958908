import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { fetchBanners } from './thunk'

const bannersAdapter = createEntityAdapter({
  selectId: (banner) => banner.bannerId,
  sortComparer: false,
})

const slice = createSlice({
  name: 'Banners',
  initialState: bannersAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(fetchBanners.fulfilled, bannersAdapter.setAll)
  },
})

export const {
  selectAll: getAllBanners
} = bannersAdapter.getSelectors((state) => state[slice.name])

export default slice.reducer
