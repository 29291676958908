// import types
import { SET_DISPLAYS } from "../../actions/types/types"

const initialState = {
  displays: [],
}

/**
 * Displays reducer
 * @param {*} state
 * @param {*} action
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISPLAYS:
      return Object.assign({}, state, { displays: action.payload.displays })
    default:
      return state
  }
}

export default reducer
