export const resourcesMessagesList = (list) => {
    let resource = []
    console.log(list,'list')
    list.forEach((i) => {
        if (i.advertisements) {
            i.advertisements.forEach((j) => {
                if ( j.advertisingType.code === 'OBT' ) { 
                    resource.push({
                        campaignId: i.campaignId,
                        name: `${i.account.name} ${i.account.surname}`,
                        sort: j.sort,
                        advertisingId: j.advertisingId,
                        advertisingTypeName: j.advertisingType.name,
                        advertisingTypeId: j.advertisingTypeId,
                        messageCeremony: j.messageObt[0].ceremony,
                        messageDate: j.messageObt[0].ceremonyDate,
                        messageHour: j.messageObt[0].ceremonyHour,
                        messageLocation: j.messageObt[0].ceremonyLocation,
                        messageTittle: j.messageObt[0].title,
                        messageHeader: j.messageObt[0].header,
                        messagePlaceWake: j.messageObt[0].placeWake,
                        message: j.messageObt[0].message,
                        messagePublicName: j.messageObt[0].publicName,
                        messagePublicEmail: j.messageObt[0].publicEmail,
                        messageNational: j.messageObt[0].priceAditional > 0 ? 'Nacional' : 'Local',
                        messageImage: j.messageObt[0].imgPriceAditional,
                        messageNameSpace: j.messageObt[0].namespace,
                        messageSunday: j.messageObt[0].priceDiffSunday,
                        messageInsignia: j.messageObt[0].insignia,
                        messageId: j.messageObt[0].messageId,
                        status: j.messageObt[0].status,
                        resourceStatus:  j.resources[0].status,
                        advertisingFormat: j.formatId
                    })
                }
            })
        }
    })

    resource = resource.sort((a, b) => {
        return a.campaignId - b.campaignId || a.sort - b.sort
      })

    return resource
}

export const nameFormatMessagesList = (data,formats) => {
    return data.map(item => {
        // Busca el formato correspondiente en la lista de formatos
        const matchingFormat = formats.find(format => format.format.formatId === item.advertisingFormat);
    
        // Agrega `formatName` al objeto si encuentra el formato
        return {
          ...item,
          formatName: matchingFormat ? matchingFormat.format.name : null
        };
      });
}