import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: { main: '#FFA217', dark: '#C85F00', hover: '#EB8D00' },
    secondary: { main: '#252627' },
    third: { main: '#006CA5' },
    success: { main: '#00AE34' },
    error: { main: '#E20000' },
    white: '#fff',
    grey_1: '#4F5356',
    grey_2: '#C4C4C4',
    blue_1: '#06699d',
    green_1: '#053814',
    blue_darkness: '#001634',

  },
  colors: {
    gray_5: '#E9E9E9',
    gray_15: '#464545',
  },
  grey: { 0: '#4F5356' },
  typography: { useNextVariants: true },
  fonts: ['sans-serif', 'Roboto', 'Open Sans', 'Roboto Slab'],
})
