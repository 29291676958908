import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  fetchMessages,
  updateCondolenceMenssage,
  updateMessageStatus
} from './thunk'

export const selectMessagesId = ({ campaignId, sort }) =>
  `${campaignId}-${sort}`

const messagesAdapter = createEntityAdapter({
  selectId: selectMessagesId,
  sortComparer: false,
})

const slice = createSlice({
    name: 'Messages',
    initialState: messagesAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(fetchMessages.fulfilled, messagesAdapter.setAll)
      builder.addCase(updateCondolenceMenssage.fulfilled, messagesAdapter.updateOne)
      builder.addCase(updateMessageStatus.fulfilled, messagesAdapter.updateOne)
    //   builder.addCase(updateSmartReport.fulfilled, materialsAdapter.updateOne)
    },
  })

export const { setError } = slice.actions

export const {
    selectAll: getAllMessages,
    selectById: getMessageById,
    selectIds: getAllMessagesIds,
    selectEntities: getAllMessageEntities
  } = messagesAdapter.getSelectors((state) => state[slice.name])
  
  export default slice.reducer
