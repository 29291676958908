// import internal modules
import { FETCH_LOADING } from '../../actions/types/types'

const initialState = {
  loading: false,
}

/**
 * Action to requests
 * @param {*} state
 * @param {*} action
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      }

    default:
      return state
  }
}
