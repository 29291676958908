export const MaterialStatus = {
  Rejected: 'Rechazado',
  Approved: 'Aprobado',
  Pending: 'Pendiente',
}

export const MessageStatus = {
  Rejected: 'Rechazado',
  Approved: 'Aprobado',
  Pending: 'Pendiente',
}

export const MaterialStrStatus = {
  Rejected: 'Imagen Rechazado',
  Approved: 'Imagen Aprobado',
  Pending: 'Imagen Pendiente',
}

export const MessageStrStatus = {
  Rejected: 'Mensaje Rechazado',
  Approved: 'Mensaje Aprobado',
  Pending: 'Mensaje Pendiente',
}

export const MaterialStatusCode = {
  Rejected: 0,
  Approved: 1,
  Pending: 2,
}

export const MessageStatusCode = {
  Rejected: 0,
  Approved: 1,
  Pending: 2,
}

export const AdvertisingTypeCode = {
  CMP: 1,
  DMP: 2,
  Content: 3,
  CPC: 5,
  CPCs: 6,
  OBT: 7,
}

export const AdvertisingType = {
  Display: 'Impresiones',
  Content: 'Contents',
  Click: 'Clics',
  Condolence: 'Condolencias',
}

export const dateFormat = 'DD/MM/YYYY'

export const imgContent = 'img/content/zu-contenido-miniatura.jpg'
