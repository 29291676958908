import axios from 'axios'
import { API_ENDPOINT } from '../../../constants/apiEndpoint'
import { UserStatus } from './constants'

/**
 * Get Users from API
 * @returns {response} response axios
 */
export const fetchAllUsers = () => {
  return axios
    .get(`${API_ENDPOINT}/users`, {
      params: {
        filter: {
          where: { status: UserStatus.Active },
          include: [{ relation: 'role' }],
        },
      },
    })
    .then((r) => r.data)
}

/**
 * Get Users by id
 * @returns {response} response axios
 */
export const fetchUserById = (userId) => {
  return axios.get(`${API_ENDPOINT}/users/${userId}`).then((r) => r.data)
}

/**
 * Create an user
 * @param {*} user user info
 */
export const createUser = (user) => {
  return axios.post(`${API_ENDPOINT}/users`, user).then((r) => r.data)
}

/**
 * Update an user
 * @param {*} id user ID
 * @param {*} body new user info
 */
export const updateUser = (id, body) => {
  return axios.patch(`${API_ENDPOINT}/users/${id}`, body).then((r) => r.data)
}

/**
 * Delete an user
 * @param {*} id user ID
 */
export const deleteUser = (id) => {
  return axios.patch(`${API_ENDPOINT}/users/${id}`, {
    status: UserStatus.Inactive,
  })
}
