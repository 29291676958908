import axios from 'axios'
import { API_ENDPOINT } from '../../../constants/apiEndpoint'

/**
 * Get Accounts from API
 * @returns {response} response axios
 */
export const fetchAllAdvertisers = () => {
  return axios
    .get(`${API_ENDPOINT}/accounts`, {
      params: {
        filter: {
          include: [
            { relation: 'documentType' },
            {
              relation: 'municipality',
              scope: { include: [{ relation: 'department' }] },
            },
          ],
        },
      },
    })
    .then((r) => r.data)
}
