import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  fetchCampaigns,
  updateCampaignUser,
  updateCampaignStatus,
} from './thunk'

const campaignsAdapter = createEntityAdapter({
  selectId: (campaign) => campaign.campaignId,
  sortComparer: false,
})

const slice = createSlice({
  name: 'Campaigns',
  initialState: campaignsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCampaigns.fulfilled, campaignsAdapter.setAll)
    builder.addCase(updateCampaignUser.fulfilled, campaignsAdapter.updateOne)
    builder.addCase(updateCampaignStatus.fulfilled, campaignsAdapter.updateOne)
  },
})

export const { setError } = slice.actions

export const {
  selectAll: getAllCampaigns,
  selectById: getCampaignById,
  selectIds: getAllCampaignsIds,
  selectEntities: getAllCampaignEntities,
} = campaignsAdapter.getSelectors((state) => state[slice.name])

export default slice.reducer
