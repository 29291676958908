/* import external modules */
import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'

/* import internal modules */
import useStyles from './styles'
import Img from '../Img'

/**
 *  @description This functional component is a spinner of loading
 *  @version 1.0.0
 *  @since 23/04/2020
 *  @author Jaime Andrés Gómez Gutiérrez <jaigom.ext@eltiempo.com>
 *
 *  @function
 *  @name Progress
 *  @returns {JSX.Element} Returns a component with material-ui items
 **/

const Progress = ({internal, ...props}) => {
    const classes = useStyles(props)

    return (
        <>
            {internal ? (
                <div className={classes.overlay}>
                    <div>
                        <Img src="/cargador.gif" width="100"/>
                        <p className={classes.text}>Cargando...</p>
                    </div>
                </div>
            ) : (
                <Backdrop className={classes.backdrop} open={true}>
                    <div>
                        <Img src="/cargador.gif" width="100"/>
                        <p className={classes.text}>Cargando...</p>
                    </div>
                </Backdrop>
            )}
        </>
    )
}

export default Progress;