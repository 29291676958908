// import internal modules
import { HANDLE_USER, SET_TOKEN, SET_USERS } from '../types/types'

export const setUser = (user) => ({
  type: HANDLE_USER,
  payload: { user },
})

export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: { token },
})

export const setUsers = (users) => ({
  type: SET_USERS,
  payload: { users },
})
