import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { fetchUsers, createUser, deleteUser, updateUser } from './thunk'

const usersAdapter = createEntityAdapter({
  selectId: (user) => user.userId,
  sortComparer: false,
})

const slice = createSlice({
  name: 'Users',
  initialState: usersAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, usersAdapter.setAll)
    builder.addCase(createUser.fulfilled, usersAdapter.addOne)
    builder.addCase(deleteUser.fulfilled, usersAdapter.removeOne)
    builder.addCase(updateUser.fulfilled, usersAdapter.upsertOne)
  },
})

export const {
  selectAll: getAllUsers,
  selectById: getUserById,
  selectIds: getAllUsersIds,
} = usersAdapter.getSelectors((state) => state[slice.name])

export default slice.reducer
